var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto fill-height d-flex flex-column",attrs:{"color":"#3e4c55"}},[_c('v-card-title',[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-credit-card-multiple ")]),_c('span',{staticClass:"title grey--text text--lighten-5"},[_vm._v(_vm._s(_vm.name))])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"md":"7"}},[_c('div',{staticClass:"overflow-y-auto overflow-x-hidden grey--text text--lighten-3",staticStyle:{"height":"80px","max-height":"80px"}},[_vm._v(" "+_vm._s(_vm.description)+" ")])]),_c('v-col',{attrs:{"md":"5"}},[_c('view-image',{attrs:{"image":_vm.image}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"col":"12","md":"125","sm":"6"}},[_c('v-btn',{attrs:{"small":"","block":"","color":"#008483","dark":""}},[_vm._v("Card Issues: "+_vm._s(_vm._f("numberFormatter")(_vm.sales))+" ")])],1),_c('v-col',{attrs:{"col":"12","md":"6","sm":"6"}},[_c('v-btn',{attrs:{"small":"","block":"","color":"#008483","dark":""}},[_vm._v("Sales: "+_vm._s(_vm._f("toCurrency")(Number(_vm.revenue))))])],1)],1)],1),_c('v-bottom-navigation',[(
        _vm.checkWritePermission(_vm.$modules.vouchers.management.slug) &&
        _vm.status_id == 1
      )?_c('v-spacer'):_vm._e(),(
        _vm.checkWritePermission(_vm.$modules.vouchers.management.slug) &&
        _vm.status_id == 1
      )?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('edit', _vm.id)}}},[_c('span',[_vm._v("Edit")]),_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),_c('v-spacer'),(
        _vm.checkReadPermission(_vm.$modules.vouchers.issues.slug) && _vm.status_id == 1
      )?_c('v-btn',{attrs:{"text":""},on:{"click":_vm.viewSales}},[_c('span',[_vm._v("View")]),_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-eye")])],1):_vm._e(),(
        _vm.checkDeletePermission(_vm.$modules.vouchers.management.slug) &&
        _vm.status_id == 1
      )?_c('v-spacer'):_vm._e(),(_vm.checkDeletePermission(_vm.$modules.vouchers.management.slug))?_c('v-btn',{on:{"click":_vm.toggleStatus}},[_c('span',[_vm._v(_vm._s(_vm.status_id == 1 ? "Delete" : "Reactivate"))]),_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-"+_vm._s(_vm.status_id == 1 ? "delete" : "refresh"))])],1):_vm._e(),_c('v-spacer')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }