<template>
  <v-card class="mx-auto fill-height d-flex flex-column" color="#3e4c55">
    <v-card-title>
      <v-icon dark left> mdi-credit-card-multiple </v-icon>
      <span class="title grey--text text--lighten-5">{{ name }}</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="7">
          <div
            style="height: 80px; max-height: 80px"
            class="overflow-y-auto overflow-x-hidden grey--text text--lighten-3"
          >
            {{ description }}
          </div>
        </v-col>
        <v-col md="5">
          <view-image :image="image"></view-image>
        </v-col>
      </v-row>

      <v-row>
        <v-col col="12" md="125" sm="6">
          <v-btn small block color="#008483" dark
            >Card Issues:
            {{ sales | numberFormatter }}
          </v-btn>
        </v-col>
        <v-col col="12" md="6" sm="6">
          <v-btn small block color="#008483" dark
            >Sales: {{ Number(revenue) | toCurrency }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-text>

    <v-bottom-navigation>
      <v-spacer
        v-if="
          checkWritePermission($modules.vouchers.management.slug) &&
          status_id == 1
        "
      ></v-spacer>
      <v-btn
        v-if="
          checkWritePermission($modules.vouchers.management.slug) &&
          status_id == 1
        "
        text
        @click="$emit('edit', id)"
      >
        <span>Edit</span>
        <v-icon medium>mdi-pencil</v-icon>
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        @click="viewSales"
        v-if="
          checkReadPermission($modules.vouchers.issues.slug) && status_id == 1
        "
        text
      >
        <span>View</span>
        <v-icon medium>mdi-eye</v-icon>
      </v-btn>

      <v-spacer
        v-if="
          checkDeletePermission($modules.vouchers.management.slug) &&
          status_id == 1
        "
      ></v-spacer>
      <v-btn
        v-if="checkDeletePermission($modules.vouchers.management.slug)"
        @click="toggleStatus"
      >
        <span>{{ status_id == 1 ? "Delete" : "Reactivate" }}</span>
        <v-icon medium>mdi-{{ status_id == 1 ? "delete" : "refresh" }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
    </v-bottom-navigation>
  </v-card>
</template>

<script>
export default {
  props: {
    name: { type: String, default: "" },
    type: { type: String, default: "" },
    id: { type: Number, default: 0 },
    image: { type: String, default: "0" },
    description: { type: String, default: "0" },
    revenue: { type: Number, default: 0 },
    sales: { type: Number, default: 0 },
    status_id: { type: Number, default: 0 },
  },

  methods: {
    edit() {
      this.$emit("edit", this.id);
    },
    toggleStatus() {
      this.$emit("delete", {
        id: this.id,
        type: this.status_id == 1 ? "delete" : "activate",
      });
    },
    viewSales() {
      this.$router.push({
        name: "VoucherSales",
        params: { data: btoa(this.id) },
      });
    },
  },
};
</script>

<style></style>
