<template>
  <v-container fluid no-gutters>
    <v-row>
      <v-col lg="4" sm="4"> </v-col>
      <v-spacer></v-spacer>
      <v-col lg="4" sm="4" style="text-align: center">
        <v-btn-toggle
          borderless
          class="button_navigation"
          v-model="toggle_mode"
          mandatory
          @change="loadVouchers"
        >
          <v-btn value="1">Active</v-btn>
          <v-btn value="10">Expired</v-btn>
          <v-btn value="2">InActive</v-btn>
        </v-btn-toggle>
      </v-col>
      <v-spacer></v-spacer>
      <v-col class="text-lg-right" lg="4" sm="4">
        <v-btn
          v-if="checkWritePermission($modules.vouchers.management.slug)"
          class="teal-color"
          @click="addVoucherPopup"
          dark
        >
          Voucher
          <v-icon right dark>mdi-plus-circle</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="voucher in voucherList" :key="voucher.id" lg="4">
        <voucher-widget
          v-bind="voucher"
          @edit="populateEditVoucher"
          @delete="deleteVoucherWarning"
        />
      </v-col>
    </v-row>
    <h3 class="text-center mt-12" v-if="voucherList.length == 0">
      No
      {{
        toggle_mode == 1 ? "active" : toggle_mode == 10 ? "expired" : "inactive"
      }}
      voucher present
    </h3>
    <v-pagination
      v-if="totalPages"
      v-model="page"
      :length="totalPages"
    ></v-pagination>
    <v-dialog v-model="addVoucherDialoge" scrollable width="40%">
      <v-form ref="form" v-model="valid">
        <v-card>
          <v-card-title class="headline">
            <div class="head">
              {{ voucher.id != null ? "Update" : "Add" }} Voucher
            </div>
          </v-card-title>
          <v-card-text class="form_bg">
            <v-row>
              <v-col md="12">
                <v-row no-gutters justify="center">
                  <v-col md="6"
                    ><image-upload
                      @upload="
                        (data) => {
                          voucher.image = data;
                        }
                      "
                      @remove="
                        () => {
                          voucher.image = null;
                        }
                      "
                      :image_path="voucher.image_path"
                      :height="160"
                      defaultImage="default"
                    ></image-upload
                  ></v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="voucher.name"
                  outlined
                  background-color="#fff"
                  label="Name*"
                  :hint="voucher.name && 'Name'"
                  persistent-hint
                  required
                  :rules="[(v) => !!v || 'Name is required']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="voucher.description"
                  label="Description"
                  :hint="voucher.description && 'Description'"
                  persistent-hint
                  outlined
                  background-color="#fff"
                  required
                  rows="3"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="voucher.voucher_type"
                  label="Type*"
                  :hint="voucher.voucher_type && 'Type'"
                  persistent-hint
                  :items="[
                    { name: 'Custom', value: 'c' },
                    { name: 'With Face Value', value: 'v' },
                  ]"
                  :rules="[(v) => !!v || 'Voucher type is required']"
                  item-text="name"
                  item-value="value"
                  outlined
                  :menu-props="{ bottom: true, offsetY: true }"
                  background-color="#fff"
                  required
                  rows="3"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="6" v-if="voucher.voucher_type != 'c'">
                <v-text-field
                  v-model="voucher.price"
                  label="Price*"
                  :hint="voucher.price && 'Price'"
                  persistent-hint
                  :prefix="currencyCode"
                  outlined
                  background-color="#fff"
                  :rules="[
                    (v) => !!v || 'Package Price is required',
                    (v) => !isNaN(v) || 'Package Price must be Number',
                  ]"
                  required
                  rows="3"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2 white--text blue-color"
              @click="cancelVoucherDialoge"
              >Close</v-btn
            >
            <v-btn
              class="ma-2 white--text teal-color"
              @click="addOrEditVoucher"
              >{{ voucher.id != null ? "Update" : "Add" }}</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <confirm-model
      v-bind="confirmModel"
      @confirm="confirmActions"
      @close="confirmModel.id = null"
    ></confirm-model>
  </v-container>
</template>

<script>
import VoucherWidget from "@/components/Voucher/VoucherWidget";
import ConfirmModel from "@/components/Confirmation/ConfirmModel";

export default {
  components: {
    VoucherWidget,
    ConfirmModel,
  },
  mounted() {
    this.loadVouchers();
  },
  data() {
    return {
      voucher: {},
      addVoucherDialoge: false,
      valid: true,
      page: 1,
      voucherList: [],
      totalPages: 0,
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      benefitIds: [],
      btnShow: false,
      toggle_mode: "1",
    };
  },
  watch: {
    page() {
      this.loadVouchers();
    },
  },

  methods: {
    loadVouchers() {
      this.$http
        .get(
          `venues/vouchers?page=${this.page}&per_page=6&status_id=${this.toggle_mode}`
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.voucherList = response.data.data;
            this.totalPages = response.data.total_pages;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    populateEditVoucher(id) {
      this.$http
        .get("venues/vouchers/" + id)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            const data = response.data.data;
            this.voucher.name = data.name;
            this.voucher.id = data.id;
            this.voucher.description = data.description;
            this.voucher.voucher_type = data.voucher_type;
            this.voucher.price = data.price;
            this.voucher.image = null;
            this.voucher.product_type_id = data.product_type_id;
            this.voucher.image_path = data.image;
            this.addVoucherDialoge = true;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    addVoucherPopup() {
      this.voucher = {};
      this.addVoucherDialoge = true;
      setTimeout(() => {
        this.$refs.form.resetValidation();
      }, 0);
    },
    addOrEditVoucher() {
      if (!this.$refs.form.validate()) {
        this.showError("Please enter all required fields");
        return;
      }
      let formData = new FormData();
      formData.set("name", this.voucher.name);
      formData.set("id", this.voucher.id);
      if (this.voucher.description)
        formData.set("description", this.voucher.description);
      formData.set("voucher_type", this.voucher.voucher_type);
      if (this.voucher.price) {
        if (this.voucher.price <= 0) {
          this.showError("Price should be greater than zero");
          return;
        }
        formData.set("price", this.voucher.price);
      }
      if (this.voucher.product_type_id)
        formData.set("product_type_id", this.voucher.product_type_id);
      if (this.voucher.image) formData.set("image", this.voucher.image);
      this.showLoader();
      this.$http
        .post(
          `venues/vouchers${this.voucher.id ? "/" + this.voucher.id : ""}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; boundary=${form._boundary}",
            },
          }
        )
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            let message =
              this.voucher.id != null
                ? "Voucher updated successfully"
                : "Voucher added successfully";
            this.showSuccess(message);
            this.loadVouchers();
            this.hideLoader();
            this.addVoucherDialoge = false;
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    changeVoucherStatus(id) {
      this.$http
        .delete("venues/vouchers/" + id)
        .then((response) => {
          if (response.status == 200 && response.data.status == true) {
            this.loadVouchers();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    confirmActions(data) {
      if (data.type == "delete") {
        this.changeVoucherStatus(data.id);
      }
      this.$forceUpdate();
      this.confirmModel.id = null;
    },
    deleteVoucherWarning(data) {
      this.confirmModel = {
        id: data.id,
        title: `Do you want to ${data.type} this Voucher?`,
        description:
          "By clicking <b>Yes</b> you can confirm deleting this Voucher.  Do you need to continue your action ?",
        type: "delete",
      };
    },
    cancelVoucherDialoge() {
      setTimeout(() => {
        this.$refs.form.resetValidation();
      }, 0);
      this.addVoucherDialoge = false;
    },
  },
};
</script>

<style scoped></style>
